<template>
  <WeContainer card="">
    <div class="card">
      <div class="card-header pt-4 pb-4 custom-header-bg">
        <h5 class="font-weight-bold mb-0 text-danger">
          <span class="text-uppercase">SİLİNEN ÜRÜNLER</span>
        </h5>
      </div>
      <div class="card-body">
        <WeTable
          v-bind:index="false"
          v-bind:data="product.list"
          v-bind:allowSortIndex="false"
          v-bind:columns="columns"
          v-bind:actions="tableActions"
          v-on:on-action="onAction"
          v-bind:ajax="true"
          v-bind:loading="loading"
          v-bind:table-result="tableResult"
          v-on:make-request="updateProductList"
          ref="productTable"
        />
      </div>
    </div>
  </WeContainer>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "Trash",
  data() {
    return {
      loading: false,
      filter: {
        deleted_at: 1,
      },
      tableActions: [
        {
          icon: "fas fa-sync fa-sm",
          class: "btn-outline-primary",
          action: "restore",
          tooltip: "Geri Al",
        },
        {
          icon: "fas fa-trash fa-sm",
          class: "btn-outline-danger ml-2",
          action: "remove",
          tooltip: "Kalıcı Olarak Sil",
        },
      ],
      columns: [
        { name: "image", icon: "fas fa-camera", type: "image" },
        { name: "sku_no", th: "Stok Kodu", type: "string" },
        { name: "name", th: "Ürün Adı", type: "string" },
        { name: "marketplace_list", th: "Pazaryeri", type: "marketplace_list" },
        { name: "category", th: "Kategori", type: "string" },
        { name: "_quantity", th: "Miktar", type: "string", width: "10%" },
        { name: "price", th: "Fiyat", type: "currency" },
        { name: "deleted_at", th: "Silinme Tarihi", type: "datetime" },
        { name: "is_active", th: "Durum", type: "boolean_disabled" },
      ],
      tableResult: {
        item_count: 1,
        current_page: 1,
        total_page: 1,
        total_items: 1,
      },
    };
  },
  methods: {
    ...mapActions("product", ["getList", "restore", "remove"]),
    onAction(data) {
      if (data.key == "remove") {
        this.onRemove(data.row);
      } else if (data.key == "restore") {
        this.onRestore(data.row);
      }
    },
    onRestore(row) {
      let message = `${row.name} adlı ürünü geri almak istediğinize emin misiniz ?`;
      this.$swal({
        title: message,
        icon: "question",
        showCancelButton: true,
        cancelButtonText: "İptal",
        confirmButtonText: "Geri Al",
      }).then((confirm) => {
        if (confirm.isConfirmed) {
          const checkPermission = this.checkPermission("r");
          if (!checkPermission) {
            permission.alert(this);
          } else {
            this.restore({
              id: row.id,
              onSuccess: (result) => {
                if (result && result.data && result.data.status !== "error") {
                  this.$toast.success("Ürün Başarıyla Geri Alındı");
                  this.updateProductList();
                } else {
                  this.$swal({
                    title: "Ürün Geri Alınamadı",
                    text: result.data.message,
                    icon: "error",
                    confirmButtonText: "Tamam",
                  });
                }
              },
            });
          }
        }
      });
    },
    onRemove(row) {
      let message = `${row.name} adlı ürünü silmek istediğinize emin misiniz ?`;
      this.$swal({
        title: message,
        text: "Bu işlem geri alınamaz.",
        icon: "question",
        showCancelButton: true,
        cancelButtonText: "İptal",
        confirmButtonText: "Sil",
      }).then((confirm) => {
        if (confirm.isConfirmed) {
          const checkPermission = this.checkPermission("f");
          if (!checkPermission) {
            permission.alert(this);
          } else {
            this.remove({
              id: row.id,
              onSuccess: (result) => {
                if (result && result.data && result.data.status !== "error") {
                  this.$toast.success("Ürün Başarıyla Silindi");
                  this.updateProductList();
                } else {
                  this.$swal({
                    title: "Ürün Silinemedi",
                    text: result.data.message,
                    icon: "error",
                    confirmButtonText: "Tamam",
                  });
                }
              },
            });
          }
        }
      });
    },
    updateProductList(requestData = null) {
      requestData = requestData || {
        page: 1,
        limit: 25,
      };

      this.loading = true;

      this.getList({
        filter: this.filter,
        table: requestData,
        onSuccess: (result) => {
          helper.copy(result, this.tableResult, ["items", "status"]);
        },
        onFinish: () => {
          this.loading = false;
        },
      });
    },
    checkPermission(code) {
      return permission.check("product", code);
    },
  },
  computed: {
    ...mapState({
      shared: (state) => state.shared,
      product: (state) => state.product,
    }),
    ...mapState(["session"])
  },
  mounted() {
    let locales = this.session?.config["site.available_locales"];
    if(locales != undefined){
      locales = JSON.parse(locales)
      if(locales.length > 1) this.columns.push({ name: "locales", th: "Dil", type: "language"})
    }
  }
};
</script>

<style lang="scss" scoped>
.img-popover {
  color: #2f3542;
}

.img-popover:hover {
  text-decoration: none;
  color: #535455;
}
</style>
